




































































































import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Application, ApplicationService } from '../../../libs/application';
import { OAuth2Service } from '../../../libs/oauth2';
import { LocalDatabaseStrategy } from '../../../libs/strategies';
import { routeToErrorPage } from '../../../libs/util';

export default Vue.extend({
  mixins: [validationMixin],
  data() {
    return {
      isMounted: false,
      isFormLoading: false,

      request: OAuth2Service.getAuthorizationRequestFromRoute(this.$route),
      application: null as Application | null,
      connection: this.$route.query.connection?.toString(),

      form: {
        email: '',
      },
      requestedPassword: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
    },
  },

  async mounted() {
    if (!this.request.client_id) {
      routeToErrorPage();
      return;
    }

    try {
      this.$set(
        this,
        'application',
        await ApplicationService.getByClientID(this.request.client_id),
      );
    } catch (e) {
      routeToErrorPage();
      return;
    }

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async onSubmit() {
      this.$set(this, 'isFormLoading', true);
      try {
        await LocalDatabaseStrategy.resetPasswordRequest(
          this.request.client_id,
          this.connection,
          this.form.email,
        );
      } catch (e) {
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isFormLoading', false);
      }

      // On success, show other text
      this.$set(this, 'requestedPassword', true);
    },
  },
});
